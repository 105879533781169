import React, {
  useState,
  useRef,
  useReducer,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { Remarkable } from "remarkable";
import classnames from "classnames";
import Media, { useMedia } from "react-media";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { PageContext } from "../../context/page-context";
import { RowContext } from "../../context/row-context";

// import ScrollBar from "react-scrollbars-custom";
import ScrollBar from "../../containers/Scrollbar";

import {
  ApplyButton,
  ListCareers,
  careersTitle,
  careerLink,
  posLocation,
  SingleCareer,
  PositionWrap,
  posDescrpition,
  posTitle,
  positionHeader,
  ApplicationForm,
  cfTitle,
  formField,
  mobFormField,
  formElement,
  formInput,
  formTextarea,
  FormLabel,
  FormLabelName,
  ErrorField,
  buttonSubmit,
  checkboxLabel,
  uploadFile,
  formInputFile,
  formLabelUpload,
  CloseButton,
  closeButton,
  messageSent,
  messageSentContainer,
} from "./Components";

gsap.registerPlugin(ScrollToPlugin);

const md = new Remarkable({ html: true, breaks: true });

const initialState = {
  department: "",
  name: "",
  email: "",
  message: "",
  acceptance: false,
  recaptcha: false,
};

const initialErrors = {
  department: "",
  name: "",
  email: "",
  message: "",
  acceptance: "",
  recaptcha: "",
};

const errors = Object.keys(initialState).reduce((prev, curr) => {
  prev[curr] = "";
  return prev;
}, {});

function reducer(state, { field, value, type }) {
  switch (type) {
    case "update":
      return {
        ...state,
        [field]: value,
      };
    case "reset":
    default:
      return {
        ...initialState,
      };
  }
}

function errorsReducer(state, newState) {
  return {
    ...state,
    ...newState,
  };
}

const Careers = () => {
  const data = useStaticQuery(graphql`
    query CareersQueryQA {
      allStrapiOpenPosition {
        nodes {
          id
          location
          slug
          title
          description
          referral
        }
      }
    }
  `);
  const { mobHeaderState } = useContext(PageContext);
  const { rowState, setRowState } = useContext(RowContext);
  const { nodes: positions } = data.allStrapiOpenPosition;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorsState, setErrors] = useReducer(errorsReducer, initialErrors);
  const [currPos, setPos] = useState();
  const [showApply, setShowApply] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const active = typeof currPos !== "undefined";
  const formRef = useRef(null);
  const captchaRef = useRef(null);
  const [triggerReflow, setReflow] = useState(0);
  const isMobile = useMedia({
    query: "(max-width: 768px)",
  });

  const onBlur = (e) => {
    const field = e.target.name.substr(5);
    const value = field === "acceptance" ? e.target.checked : e.target.value;

    dispatch({ field, value, type: "update" });
  };

  const onClickPosition = (slug, i) => {
    if (rowState === "normal" && !active) {
      setRowState("openPosition");
    }
    setPos(i);
    setReflow((triggerReflow) => triggerReflow + 1);

    if (isMobile) {
      let headerOffset = 0;
      if (mobHeaderState === "visible") {
        headerOffset = window.innerWidth < 600 ? -77 : -83;
      }

      setTimeout(() => {
        gsap.to(window, {
          scrollTo: {
            y:
              document.querySelector("#careers").offsetTop +
              document.querySelector(".single-career").offsetTop +
              headerOffset,
          },
        });
      }, 50);
    }
  };

  const closePosition = () => {
    setPos(undefined);
    document.body.classList.remove("open-position");
  };

  const applyTo = () => {
    if (isMobile) {
      let headerOffset = 0;
      if (mobHeaderState === "visible") {
        headerOffset = window.innerWidth < 600 ? -77 : -83;
      }

      gsap.to(window, {
        scrollTo: {
          y:
            document.querySelector("#careers").offsetTop +
            document.querySelector("#application-form").offsetTop +
            headerOffset,
        },
      });
    } else {
      setShowApply(!showApply);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    ["file", "telephone", "name", "message"].forEach((el) => {
      errors[el] = !state[el] ? "This field is required" : "";
    });

    errors.email = !emailReg.test(state.email) ? "Invalid email" : "";
    errors.acceptance = !state.acceptance
      ? "You must accept the Personal Data Protection Policy"
      : "";

    if (
      window.grecaptcha &&
      window.grecaptcha.getResponse(captchaRef.current)
    ) {
      errors.recaptcha = "";
    } else {
      errors.recaptcha = "You must pass the anti-spam check";
    }

    setErrors(errors);

    if (Object.keys(errors).every((el) => errors[el] === "")) {
      const formData = new FormData();
      for (let key in state) {
        formData.append(key, state[key]);
      }

      fetch(process.env.MAIL_URL, {
        method: "POST",
        body: formData,
      })
        .then((e) => {
          if (e.url === process.env.MAIL_URL && e.ok) {
            dispatch({ type: "reset" });
            // window.alert("Your message was sent successfuly!");
            setFormSent(true);
            setTimeout(() => {
              formRef.current.reset();
            }, 1000);
            setTimeout(() => {
              setFormSent(false);
            }, 5000);
          } else {
            window.alert(
              "Your message cannot be sent at this time. Internal server error 500."
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  // positions.length &&
  //   positions.sort((a, b) => {
  //     if (
  //       (a.item_order === null && b.item_order !== null) ||
  //       (a.item_order > b.item_order && b.item_order !== null) ||
  //       (a.item_order === null && b.item_order === null)
  //     ) {
  //       return 1;
  //     } else if (
  //       a.item_order === null ||
  //       b.item_order === null ||
  //       a.item_order < b.item_order
  //     ) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   });

  const sorted_position = useMemo(() => {
    if (!positions.length) {
      return [];
    }

    return [...positions]
      .map((el, i) => {
        el.initialSort = i;
        return el;
      })
      .sort((a, b) => {
        if (a.item_order === null && b.item_order === null) {
          return a.initialSort - b.initialSort;
        } else if (a.item_order === null || b.item_order === null) {
          return b.item_order - a.item_order;
        } else {
          return a.item_order - b.item_order;
        }
      });
  }, [positions]);

  const closeApplyForm = () => {
    setShowApply(false);
  };

  useEffect(() => {
    if (typeof window === "undefined" || !window.recaptcha) {
      return;
    }

    const captchaDiv = document.querySelector(
      "#g-recaptcha-contact-application"
    );

    if (!captchaDiv.childElementCount) {
      captchaRef.current = window.grecaptcha.render(
        "g-recaptcha-contact-application",
        {
          sitekey: process.env.G_CAPTCHA_SITE_KEY,
        }
      );
    }
  }, [window.recaptcha]); // eslint-disable-line react-hooks/exhaustive-deps
  // ^ I need to retrigger on recaptcha load

  return (
    <>
      <Media
        queries={{
          mobile: "(max-width: 768px)",
          tablet:
            "(min-width: 768px) and (orientation: portrait), (min-width: 991px)",
          desktop: "(min-width: 1366px)",
        }}
        defaultMatches={{ mobile: true }}
      >
        {(matches) =>
          matches.mobile ? (
            <ListCareers className="hide-on-expanded">
              <h2 css={careersTitle}>Join us</h2>
              <p css={{ color: "#fff", marginBottom: "35px" }}>
                We're always interested to hear from talented individuals who
                want to join our team.
              </p>
              <h2 css={careersTitle}>Open Positions</h2>
              {sorted_position.length ? (
                sorted_position.map((el, i) => (
                  <p
                    role="presentation"
                    key={i}
                    css={careerLink}
                    className={classnames({ active: i === currPos })}
                    onClick={() => onClickPosition(el.slug, i)}
                  >
                    {el.title}
                    <span css={posLocation}>{el.location}</span>
                  </p>
                ))
              ) : (
                <p>Currently there are no open positions</p>
              )}
            </ListCareers>
          ) : (
            <ListCareers className="hide-on-expanded">
              <ScrollBar style={{ height: "35vh" }}>
                <h2 css={careersTitle}>Join us</h2>
                <p css={{ color: "#fff", marginBottom: "35px" }}>
                  We're always interested to hear from talented individuals who
                  want to join our team.
                </p>
                <h2 css={careersTitle}>Open Positions</h2>
                {sorted_position.length ? (
                  sorted_position.map((el, i) => (
                    <p
                      role="presentation"
                      key={i}
                      css={careerLink}
                      className={classnames({ active: i === currPos })}
                      onClick={() => onClickPosition(el.slug, i)}
                    >
                      {el.title}
                      <span css={posLocation}>{el.location}</span>
                    </p>
                  ))
                ) : (
                  <p css={{ color: "#fff", marginBottom: "35px" }}>
                    Currently there are no open positions.
                  </p>
                )}
              </ScrollBar>
            </ListCareers>
          )
        }
      </Media>
      <SingleCareer
        className={classnames("single-career", { "open-position": active })}
      >
        {active ? (
          <PositionWrap className="no-scroll-zone">
            <div css={positionHeader}>
              <button
                className="close-button"
                onClick={closePosition}
                css={[closeButton, { top: 0, left: "initial", right: "10px" }]}
              >
                <CloseButton />
              </button>
              <h2 css={posTitle}>{sorted_position[currPos].title}</h2>
              <ApplyButton
                className={classnames({ visible: showApply })}
                onClick={applyTo}
              />
            </div>
            <ScrollBar>
              <div
                css={posDescrpition}
                dangerouslySetInnerHTML={{
                  __html: md.render(sorted_position[currPos].description),
                }}
              ></div>
            </ScrollBar>
          </PositionWrap>
        ) : (
          ""
        )}
      </SingleCareer>
      <ApplicationForm
        id="application-form"
        className={classnames("no-scroll-zone", {
          visible: showApply,
          "mob-active": active,
        })}
      >
        <button
          className="close-button"
          onClick={closeApplyForm}
          css={closeButton}
        >
          <CloseButton />
        </button>
        <h2 css={cfTitle}>Application Form</h2>
        <p css={{ fontWeight: "bold", color: "#3A383C", margin: "0 0 25px" }}>
          Referral number:{" "}
          {sorted_position[currPos] ? sorted_position[currPos].referral : ""}
        </p>
        <ScrollBar triggerReflow={triggerReflow} wrapper={false}>
          <form ref={formRef} noValidate>
            <div css={[formField]}>
              <FormLabel htmlFor="form-career-name">
                <FormLabelName>Full Name*</FormLabelName>
                <ErrorField>{errorsState.name}</ErrorField>
              </FormLabel>
              <input
                css={[formElement, formInput]}
                type="text"
                id="form-career-name"
                name="form-career-name"
                aria-label="Name"
                onBlur={onBlur}
              />
            </div>
            <div css={[formField]}>
              <FormLabel htmlFor="form-career-telephone">
                <FormLabelName>Telephone*</FormLabelName>
                <ErrorField>{errorsState.telephone}</ErrorField>
              </FormLabel>
              <input
                css={[formElement, formInput]}
                type="tel"
                id="form-career-telephone"
                name="form-career-telephone"
                aria-label="Phone"
                onBlur={onBlur}
              />
            </div>
            <div css={[formField]}>
              <FormLabel htmlFor="form-career-email">
                <FormLabelName>Email*</FormLabelName>
                <ErrorField>{errorsState.email}</ErrorField>
              </FormLabel>
              <input
                css={[formElement, formInput]}
                type="email"
                id="form-career-email"
                name="form-career-email"
                aria-label="Email"
                onBlur={onBlur}
              />
            </div>
            <div css={[formField]}>
              <FormLabel htmlFor="form-career-upload" css={formLabelUpload}>
                <FormLabelName>Your CV (doc, docx, pdf)* </FormLabelName>
                <span css={uploadFile}>Upload file</span>
              </FormLabel>
              <input
                css={formInputFile}
                type="file"
                id="form-career-upload"
                name="form-career-upload"
                aria-label="File Upload"
                onBlur={onBlur}
              />
              <ErrorField
                css={{ position: "absolute", right: "10px", left: "initial" }}
              >
                {errorsState.file}
              </ErrorField>
            </div>
            <div css={[formField, { height: "auto" }]}>
              <FormLabel htmlFor="form-career-message">
                <FormLabelName>Cover Message*</FormLabelName>
                <ErrorField css={{ paddingLeft: "5px" }}>
                  {errorsState.message}
                </ErrorField>
              </FormLabel>
              <textarea
                css={[formElement, formTextarea]}
                id="form-career-message"
                name="form-career-message"
                aria-label="Message"
                onBlur={onBlur}
              />
            </div>
            <div
              css={[
                formField,
                { display: "flex", height: "auto", marginBottom: "30px" },
              ]}
            >
              <input
                type="checkbox"
                id="form-career-acceptance"
                name="form-career-acceptance"
                aria-label="Acceptance"
                onBlur={onBlur}
                css={[{ width: "20px", height: "20px" }]}
              />
              <label htmlFor="form-career-acceptance" css={checkboxLabel}>
                I confirm that I am familiar with and accept the{" "}
                <Link
                  state={{ modal: true }}
                  to="/personal-data-protection-policy"
                >
                  Personal Data Protection Policy.
                </Link>
              </label>
              <ErrorField css={{ position: "absolute", paddingLeft: "25px" }}>
                {errorsState.acceptance}
              </ErrorField>
            </div>
            <div
              css={[
                formField,
                {
                  display: "flex",
                  alignContent: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  height: "auto",
                  marginBottom: "0",
                },
                mobFormField,
              ]}
            >
              <div style={{ marginBottom: "35px", position: "relative" }}>
                <div
                  className="g-recaptcha"
                  data-sitekey={process.env.G_CAPTCHA_SITE_KEY}
                  id="g-recaptcha-contact-application"
                ></div>
                <ErrorField css={{ position: "absolute", paddingLeft: "5px" }}>
                  {errorsState.recaptcha}
                </ErrorField>
              </div>
              <div className="send-wrap">
                <button css={buttonSubmit} onClick={submitForm}>
                  Submit
                </button>
              </div>
            </div>
            <div css={[formField, { display: "inline" }]}>
              <p css={{ margin: 0 }}>* Mandatory field.</p>
            </div>
          </form>
        </ScrollBar>
        <div
          className={classnames({ "sent-successfuly": formSent })}
          css={messageSent}
        >
          <div css={messageSentContainer}>
            <p>Thank you for your message! We will reply soon.</p>
          </div>
        </div>
      </ApplicationForm>
    </>
  );
};

export default Careers;